import React, { useState } from "react";
import logo from "../assets/Logo.png";
import { FaAnglesDown } from "react-icons/fa6";
import { TiThMenu } from "react-icons/ti";
import { FaProjectDiagram, FaUser } from "react-icons/fa";
import { useLocation, useNavigate } from "react-router-dom";
import { GrAchievement } from "react-icons/gr";
import { RiContactsBookUploadFill } from "react-icons/ri";

function Nav() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();

  const isActive = (path) => location.pathname === path;
  const handleNavigation = (path) => {
    navigate(path);
    setIsMenuOpen(false);
  };

  return (
    <header className="fixed top-0 left-0 w-full z-50 bg-themColor-darkBlue drop-shadow-md">
      <div className="flex justify-between items-center py-2 px-1 md:px-10">
        <a
          href="/"
          className="flex items-center hover:scale-105 transition-all"
        >
          <img src={logo} className="h-16 mr-2" alt="logo" />
          <p className="ml-2 font-bold text-4xl text-themColor-pastel font-handWritten tracking-wide italic">
            Abhishek Kumar
          </p>
        </a>
        <ul className="hidden lg:flex items-center gap-9 md:gap-3 font-semibold">
          <li
            className={`flex flex-row items-center justify-center gap-2 px-4 py-1 rounded-xl text-lg transition-all hover:cursor-pointer ${
              isActive("/")
                ? "bg-themColor-orange text-themColor-white"
                : "hover:bg-themColor-white hover:text-themColor-blue text-themColor-white"
            }`}
            onClick={() => handleNavigation("/")}
          >
            <FaUser />
            Home
          </li>
          <li
            className={`flex flex-row items-center justify-center gap-2 px-4 py-1 rounded-xl text-lg transition-all hover:cursor-pointer ${
              isActive("/project")
                ? "bg-themColor-orange text-themColor-white"
                : "hover:bg-themColor-white hover:text-themColor-blue text-themColor-white"
            }`}
            onClick={() => handleNavigation("/project")}
          >
            <FaProjectDiagram />
            Project
          </li>
          <li
            className={`flex flex-row items-center justify-center gap-2 px-4 py-1 rounded-xl text-lg transition-all hover:cursor-pointer ${
              isActive("/achievement")
                ? "bg-themColor-orange text-themColor-white"
                : "hover:bg-themColor-white hover:text-themColor-blue text-themColor-white"
            }`}
            onClick={() => handleNavigation("/achievement")}
          >
            <GrAchievement />
            Achievement
          </li>
          <li
            className={`flex flex-row items-center justify-center gap-2 px-4 py-1 rounded-xl text-lg transition-all hover:cursor-pointer ${
              isActive("/contact")
                ? "bg-themColor-orange text-themColor-white"
                : "hover:bg-themColor-white hover:text-themColor-blue text-themColor-white"
            }`}
            onClick={() => handleNavigation("/contact")}
          >
            <RiContactsBookUploadFill />
            Contact
          </li>
        </ul>
        <div
          className="lg:hidden block text-2xl text-themColor-ligthblue cursor-pointer"
          onClick={() => setIsMenuOpen(!isMenuOpen)}
        >
          {isMenuOpen ? (
            <FaAnglesDown className="font-bold text-3xl" />
          ) : (
            <TiThMenu />
          )}
        </div>
      </div>
      <ul
        className={`absolute  top-20 tracking-wide left-0 w-full rounded-b-3xl bg-themColor-darkBlue flex flex-col items-center gap-6 font-semibold pb-10 font-roboto text-xl transform transition-transform ${
          isMenuOpen ? "opacity-100 scale-y-100" : "opacity-0 scale-y-0"
        }`}
        style={{ transition: "transform 0.3s ease, opacity 0.3s ease" }}
      >
        <li
          className={`flex flex-row items-center justify-center gap-2 list-none w-auto px-10 py-2  rounded-3xl text-center hover:cursor-pointer ${
            isActive("/")
              ? "bg-themColor-orange text-themColor-white"
              : "hover:bg-themColor-white hover:text-themColor-blue text-themColor-white"
          }`}
          onClick={() => handleNavigation("/")}
        >
          <FaUser />
          Home
        </li>
        <li
          className={`flex flex-row items-center justify-center gap-2 list-none w-auto  px-10 py-2 rounded-3xl text-center hover:cursor-pointer ${
            isActive("/project")
              ? "bg-themColor-orange text-themColor-white"
              : "hover:bg-themColor-white hover:text-themColor-blue text-themColor-white"
          }`}
          onClick={() => handleNavigation("/project")}
        >
          <FaProjectDiagram />
          Project
        </li>
        <li
          className={`flex flex-row items-center justify-center gap-2 list-none  w-auto px-10 py-2 rounded-3xl text-center hover:cursor-pointer ${
            isActive("/achievement")
              ? "bg-themColor-orange text-themColor-white"
              : "hover:bg-themColor-white hover:text-themColor-blue text-themColor-white"
          }`}
          onClick={() => handleNavigation("/achievement")}
        >
          <GrAchievement />
          Achievement
        </li>
        <li
          className={`flex flex-row items-center justify-center gap-2 list-none w-auto px-10 py-2 rounded-3xl text-center hover:cursor-pointer ${
            isActive("/contact")
              ? "bg-themColor-orange text-themColor-white"
              : "hover:bg-themColor-white hover:text-themColor-blue text-themColor-white"
          }`}
          onClick={() => handleNavigation("/contact")}
        >
          <RiContactsBookUploadFill />
          Contact
        </li>
      </ul>
    </header>
  );
}

export default Nav;
