import { BrowserRouter, Routes, Route } from "react-router-dom";
import Nav from "./components/Nav";
import Home from "./pages/Home";
import Projects from "./pages/Projects";
import Certifications from "./pages/Certification";
import LeetCode from "./pages/LeetCode";
import Contact from "./pages/Contact";

function App() {
  return (
    <BrowserRouter>
      <div className="hide-scrollbar overflow-auto h-screen">
        <div className="min-h-screen">
          <Nav />
          <div className="pt-24 mx-1">
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/project" element={<Projects />} />
              <Route path="/achievement" element={<Certifications />} />
              <Route path="/leetcode" element={<LeetCode />} />
              <Route path="/contact" element={<Contact />} />
            </Routes>
          </div>
        </div>
        {/* Uncomment if you want to add additional routes */}
        {/* <Routes>
          <Route path="/test" element={<DownloadButtom />} />
        </Routes> */}
      </div>
    </BrowserRouter>
  );
}

export default App;
