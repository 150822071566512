import React from "react";
import Typewriterc from "../components/Typewriter";
import { FaXTwitter, FaLinkedin, FaInstagram, FaGithub } from "react-icons/fa6";
import Photo from '../assets/FormalsPhoto.jpg'
import DownloadButtom from "../components/DownloadButtom";

const Home = () => (
  <section className="min-h-screen flex items-center justify-center">
    <div className="flex flex-col-reverse gap-10 md:flex-row items-center justify-between w-11/12 md:w-10/12 mx-auto p-5">
      <div className="flex flex-col items-center md:items-start text-center md:text-left">
        <h1 className="text-5xl text-themColor-white font-bold mb-4">
          Hey, I'm <span className="text-themColor-orange">Abhishek</span>
        </h1>
        <div className="text-3xl font-semibold text-themColor-white mb-6">
          I'm a{" "}
          <span className="text-themColor-orange">
            <Typewriterc />
          </span>
        </div>
        <p className="text-themColor-white text-2xl font-roboto w-">
          I'm Abhishek, a B.Tech CSE student with skills in the MERN stack and
          DSA. I've built projects like a QR ticket system and the ZENVEST
          website. I enjoy learning and tackling new tech challenges.
        </p>
        <div className="flex space-x-4 mb-8 mt-8">
          <a href="https://github.com/iamabhi0619" target="_blank" rel="noopener noreferrer" className="text-themColor-white text-3xl">
            <FaGithub />
          </a>
          <a href="https://www.instagram.com/_kumar._.abhishek/" target="_blank" rel="noopener noreferrer" className="text-themColor-white text-3xl">
            <FaInstagram />
          </a>
          <a href="https://www.linkedin.com/in/iamabhi0619/" target="_blank" rel="noopener noreferrer" className="text-themColor-white text-3xl">
            <FaLinkedin />
          </a>
          <a href="https://x.com/iamabhi0619" target="_blank" rel="noopener noreferrer" className="text-themColor-white text-3xl">
            <FaXTwitter />
          </a>
        </div>
        <div className="btn mb-8">
          <DownloadButtom/>
        </div>
      </div>
      <div className="md:w-1/2 mt-8 md:mt-0 flex justify-center md:justify-end">
        <img
          src={Photo}
          alt="Profile"
          className="rounded-full w-64 h-64 md:w-80 md:h-80 object-cover shadow-lg"
        />
      </div>
    </div>
  </section>
);

export default Home;
