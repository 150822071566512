import React from 'react';
import { Typewriter } from 'react-simple-typewriter';

const Typewriterc = () => {
  return (
    <div>
      <h1 className="text-4xl font-bold ">
        <Typewriter
          words={['WEB DEVELOPER', 'DATA STRUCTURES', 'FREELANCER']}
          loop={0}
          cursor
          cursorStyle='|'
          typeSpeed={100}
          deleteSpeed={50}
          delaySpeed={1000}
        />
      </h1>
    </div>
  );
};

export default Typewriterc;
